import { Environment } from '@abp/ng.core';

const baseUrl = 'https://portal.sid-eg.com';

export const environment = {
  production: true,
  application: {
    baseUrl: 'https://portal.sid-eg.com/',
    name: 'portal',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://portalauth.sid-eg.com',
    redirectUri: baseUrl,
    clientId: 'portal_App',
    //responseType: 'code',
    scope: 'offline_access portal role email openid profile',
    requireHttps: true
  },
  apis: {
    default: {
      url: 'https://portalauth.sid-eg.com',
      rootNamespace: 'sid.platform.portalv2',
    },
    portal: {
      url: 'https://portalapi.sid-eg.com',
      rootNamespace: 'sid.platform.portalv2',
    },
    usermanagement: {
      url: 'https://portalusermanagment.sid-eg.com',
      rootNamespace: 'sid.platform.usermanagement',
    },
    Usermanagement: {
      url: 'https://portalusermanagment.sid-eg.com',
      rootNamespace: 'sid.platform.usermanagement',
    },
  },
} as Environment;
