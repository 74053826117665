import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eportalv2RouteNames } from '../enums/route-names';

export const PORTALV2_ROUTE_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

export function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      
      
      {
        path: '/portal/backend/studies',
        name: eportalv2RouteNames.studies,
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        //parentName: eportalv2RouteNames.backend,
        order: 2,
      },
      {
        path: '/portal/backend/files',
        name: eportalv2RouteNames.files,
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        //parentName: eportalv2RouteNames.backend,
        order: 3,
      },
    
      {
        path: '/portal/frontend/admissions',
        name: eportalv2RouteNames.frontendAdmissions,
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        //parentName: eportalv2RouteNames.frontend,
        order: 4,
      },
      {
        path: '/portal/frontend/patientview',
        name: eportalv2RouteNames.patientview,
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        //parentName: eportalv2RouteNames.frontend,
        order: 5,
      },
    ]);
  };
}
