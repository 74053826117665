import { eAccountComponents } from '@abp/ng.account';
import { eLayoutType, ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  SchApplicationLayoutComponent,
  SchThemeAccountAuthWrapperComponent,
  SchThemeAccountLoginComponent,
  SchThemeAccountRegisterComponent,
  SchThemeEmptyLayoutComponent,
  SchThemeLogoComponent,
  SchThemeNavComponent,
  SchThemeNavLangComponent,
  SchThemeNavUserComponent,
  SchThemeRouteComponent,
  SchThemeTenantBoxComponent,
  SchThemeAccountLayoutComponent,
  SplashScreenService,
} from '@sid/theme';
import { LayoutService } from '@sid/theme';
import { LayoutConfig } from './layout.config';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent {
  /**
   *
   */
  constructor(
    private replaceableComponents: ReplaceableComponentsService, // injected the service
    private splashScreenService: SplashScreenService,
    private router: Router,
    private layout: LayoutService // injected the service
  ) {
    this.layout.setConfig(LayoutConfig)

    this.replaceableComponents.add({
      component: SchApplicationLayoutComponent,
      key: eThemeBasicComponents.ApplicationLayout,
    });

    this.replaceableComponents.add({
      component: SchThemeEmptyLayoutComponent,
      key: eThemeBasicComponents.EmptyLayout,
    });
    this.replaceableComponents.add({
      component: SchThemeAccountLayoutComponent,
      key: eThemeBasicComponents.AccountLayout,
    });
    this.replaceableComponents.add({
      component: SchThemeAccountLayoutComponent,
      key: eLayoutType.account,
    });
    this.replaceableComponents.add({
      component: SchThemeLogoComponent,
      key: eThemeBasicComponents.Logo,
    });
    this.replaceableComponents.add({
      component: SchThemeNavUserComponent,
      key: eThemeBasicComponents.CurrentUser,
    });
    this.replaceableComponents.add({
      component: SchThemeNavLangComponent,
      key: eThemeBasicComponents.Languages,
    });
    this.replaceableComponents.add({
      component: SchThemeNavComponent,
      key: eThemeBasicComponents.NavItems,
    });
    this.replaceableComponents.add({
      component: SchThemeRouteComponent,
      key: eThemeBasicComponents.Routes,
    });
    this.replaceableComponents.add({
      component: SchThemeAccountAuthWrapperComponent,
      key: 'Account.AuthWrapperComponent',
    });

    this.replaceableComponents.add({
      component: SchThemeAccountLoginComponent,
      key: eAccountComponents.Login,
    });
    this.replaceableComponents.add({
      component: SchThemeAccountRegisterComponent,
      key: eAccountComponents.Register,
    });
    this.replaceableComponents.add({
      component: SchThemeTenantBoxComponent,
      key: eAccountComponents.TenantBox,
    });
  }
}
