import { ModuleWithProviders, NgModule } from '@angular/core';
import { PORTALV2_ROUTE_PROVIDERS } from './providers/route.provider';

@NgModule()
export class portalv2ConfigModule {
  static forRoot(): ModuleWithProviders<portalv2ConfigModule> {
    return {
      ngModule: portalv2ConfigModule,
      providers: [PORTALV2_ROUTE_PROVIDERS],
    };
  }
}
